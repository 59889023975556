<template>
  <div>
    <div class="row">
      <div class="col-md-12 mb-2">
        <span class="font-weight-bold">Daftar Promo Teratas</span>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-start mb-2">
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisWeek"
              @click="Filter('thisWeek')"
            >
              Minggu Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisMonth"
              @click="Filter('thisMonth')"
            >
              Bulan Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.lastMonth"
              @click="Filter('lastMonth')"
            >
              Bulan Kemarin
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisYear"
              @click="Filter('thisYear')"
            >
              Tahun Ini
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-table
          striped
          hover
          responsive
          class="mt-3"
          :items="itemPromo"
          :fields="fields"
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width:
                  field.key === 'actions'
                    ? purpose == 'master'
                      ? '30%'
                      : ''
                    : '',
              }"
            />
          </template>
          <template #cell(amount)="data">
            {{ parseInt(data.item.amount).toLocaleString("id-ID") }}
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    purpose: String,
    selectedItem: Array,
    dataId: Number,
  },

  data() {
    return {
      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      period: {
        thisWeek: "btn-purple",
        thisMonth: "btn-outline-purple",
        lastMonth: "btn-outline-purple",
        thisYear: "btn-outline-purple",
      },

      tooltipPeriod: "thisWeek",
      // Pagination
      perPage: 20,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 0,
      // Table
      itemPromo: [],

      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
        },
        {
          key: "counts",
          label: "Jml. Transaksi",
          sortable: true,
        },
        {
          key: "amount",
          label: "Total Potongan",
          sortable: true,
        },
      ],
      // User Access
      btn: false,
      btnAddDoctorCommission: false,
    };
  },

  methods: {
    Filter(tool) {
      // this.$root.$emit("filterChartPatient", tool);
      this.tooltipPeriod = tool;
      this.chartLoad = false;
      if (tool == "thisWeek") {
        this.period.thisWeek = "btn-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "lastMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisYear") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-purple";
      }
      this.pagination();
    },

    async pagination() {
      let filterParams = `?filter=${this.tooltipPeriod}`;

      let response = await module.paginate(
        "top-list-promo-dashboard",
        `${filterParams}`
      );

      this.itemPromo = response.data;

    },
  },

  mounted() {
    // Get Data
    this.pagination();
    this.setActiveButton();
  },
};
</script>

<style>
.btn-outline-purple {
  border-radius: 10px !important;
  border: 1px solid #f47b20;
  color: #f47b20;
  /* border: 1px solid #5c4ea5;
  color: #5c4ea5; */
}

.btn-outline-purple:hover {
  border-radius: 10px !important;
  background-color: #f47b20;
  color: white;
  /* background-color: #5c4ea5;
  color: white; */
}

.btn-purple,
.btn-purple:hover {
  border-radius: 10px !important;
  background-color: #f47b20;
  color: white !important;
  /* background-color: #5c4ea5;
  color: white !important; */
}

.text-notes {
  color: #bfc0c5 !important;
  font-size: 10px;
}

.notes {
  position: absolute;
  bottom: 14px;
  right: 20px;
}

/* .btn-purple:hover{
  background-color: #551871;
  color: white;
} */
</style>