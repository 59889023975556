var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-start mb-2"},[_c('button',{class:'btn btn-sm filter-btn mx-1 ' + this.period.thisWeek,attrs:{"type":"button"},on:{"click":function($event){return _vm.Filter('thisWeek')}}},[_vm._v(" Minggu Ini ")]),_c('button',{class:'btn btn-sm filter-btn mx-1 ' + this.period.thisMonth,attrs:{"type":"button"},on:{"click":function($event){return _vm.Filter('thisMonth')}}},[_vm._v(" Bulan Ini ")]),_c('button',{class:'btn btn-sm filter-btn mx-1 ' + this.period.lastMonth,attrs:{"type":"button"},on:{"click":function($event){return _vm.Filter('lastMonth')}}},[_vm._v(" Bulan Kemarin ")]),_c('button',{class:'btn btn-sm filter-btn mx-1 ' + this.period.thisYear,attrs:{"type":"button"},on:{"click":function($event){return _vm.Filter('thisYear')}}},[_vm._v(" Tahun Ini ")])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","responsive":"","items":_vm.itemPromo,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
              width:
                field.key === 'actions'
                  ? _vm.purpose == 'master'
                    ? '30%'
                    : ''
                  : '',
            })})})}},{key:"cell(amount)",fn:function(data){return [_vm._v(" "+_vm._s(parseInt(data.item.amount).toLocaleString("id-ID"))+" ")]}}])})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 mb-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Daftar Promo Teratas")])])
}]

export { render, staticRenderFns }